
import { useSessionsList, useUserData, } from '../../../hooks';
import { useState,} from 'react';
import {Redirect } from 'react-router-dom';
import { Box, Typography, Button } from '@material-ui/core';

import AddEditSessionDialog from '../../../components/AddEditSessionDialog/AddEditSessionDialog';

export default function SessionsPage() {
    const [sessionsData, sessionDataIsLoading] = useSessionsList("dateCreated", 1);
    const [userData, userDataLoading] = useUserData();
    const [sessDialogIsOpen, setSessDialogIsOpen] = useState(false);

    const onAddSessionCLick = () => {
        setSessDialogIsOpen(true);
    }
    
    const onSessDialogClose = () => {
        setSessDialogIsOpen(false);
    }

    if(sessionDataIsLoading || userDataLoading) {
        return (
            <Box>
                <Typography variant="h3" component="h2">Loading</Typography>
            </Box>
        )
    }
    if(!userData.sessionsCount) {
        return (
            <>
                <AddEditSessionDialog open={sessDialogIsOpen} dialogType="add" onClose={onSessDialogClose} />
                <Box width={1} my={3} p={4} display="flex" flexDirection="column" alignItems="center" style={{border: '2px dashed #ccc', color: '#787878'}}>
                <Typography variant="h3" component="h2">No session added yet</Typography>
                <p>Click the button below to create a new session</p>
                <Button variant="contained" color="secondary" onClick={onAddSessionCLick}>Add a new session</Button>
                </Box>
            </>
        );
    }
    else if(sessionsData.length > 0) {
        const redirectUrl = `/app/sessions/${sessionsData[0].id}`;
        return (<Redirect to={redirectUrl} />)
    }
}