import { useContext, useState, useEffect }  from 'react';
import Button from '@material-ui/core/Button';
import { firestorage } from '../../../../config/firebase';
import { UserContext } from '../../../../providers/UserProvider';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from "@material-ui/core";
import { firefunctions } from '../../../../config/firebase';
import { SESSION_STATUS } from '../../../../enums';

export default function DownloadFilesButton ({ sessionData, sessionCounts }) {
    const currentUser = useContext(UserContext);
    const [csvLink, setCsvUrl] = useState(null);
    const [zipLink, setZipUrl] = useState(null);
    const [jsonLink, setJsonUrl] = useState(null);
    const downloadSessionStatuses = [SESSION_STATUS.ABORTED, SESSION_STATUS.DONE, SESSION_STATUS.DONE_WITH_ERROR, SESSION_STATUS.ERROR];

    const generateCSVLink = () => {
        firestorage.ref(`users/${currentUser.uid}/${sessionData.id}/corpus-csv.csv`)
            .getDownloadURL()
            .then((url) => {
                setCsvUrl(url);
            })
            .catch((error) => {
                switch (error.code) {
                    case 'storage/object-not-found':
                        var functionRef = firefunctions.httpsCallable('onGenerateFiles');
                        functionRef({ sessionId: sessionData.id }).then((msg) => {
                            console.log('generating files')
                        }, (err) => {
                            
                        });
                        break;
                    default: 
                    break;
                }
            })
    }

    const generateZipLink = () => {
        firestorage.ref(`users/${currentUser.uid}/${sessionData.id}/corpus-zip.zip`)
            .getDownloadURL()
            .then((url) => {
                setZipUrl(url);
            })
    }

    const generateJSONLink = () => {
        firestorage.ref(`users/${currentUser.uid}/${sessionData.id}/corpus-json.json`)
            .getDownloadURL()
            .then((url) => {
                setJsonUrl(url);
            })
    }

    useEffect(()=> {
        if(sessionData && sessionData.downloadFilesReady && sessionCounts.success > 0 && downloadSessionStatuses.includes(sessionData.status)) {
            try {
            generateCSVLink();
            generateZipLink();
            if(sessionData.includesJson) {
                generateJSONLink();
            }
            } catch(exception) {
                
            }
        }
    }, [sessionData.downloadFilesReady, sessionCounts]);

    
    if(!sessionData.downloadFilesReady && sessionCounts.success > 0 && (_.isEmpty(csvLink) || _.isEmpty(zipLink))) {
        return (<div><CircularProgress /> &nbsp; Working on load files... just a moment..</div>)
    }
    if(sessionData.downloadFilesReady && sessionCounts.success > 0 && !_.isEmpty(csvLink) && !_.isEmpty(zipLink)) {
        return (
                <Box display="flex"flexDirection="row" mt={1}>
                    {zipLink && <Box><Button size="small" variant="outlined" color="secondary" href={zipLink}>Download Zip Folder</Button></Box> }
                    { csvLink && <Box ml={1}><Button size="small" variant="outlined" color="secondary" href={csvLink}>Download CSV File</Button></Box> }
                    { jsonLink && <Box ml={1}><Button size="small" variant="outlined" color="secondary" href={jsonLink} target="_blank">Download JSON File</Button></Box> }
                </Box>
        )
    } else if(sessionData.downloadFilesReady && sessionCounts.success === 0) {
        return (<p><strong>Empty Results. Not URLs were scraped.</strong></p>)
    } else {
        return null;
    }
}