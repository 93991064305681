import { useState, useEffect }  from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function LogsFilter({ sessionData, sessionCounts={}, onSetFilter = ()=> {} }) {

    let defaultState = {
        success: { key: 'success', checked: true, count: 0, label: 'Success', checkboxStyle:{color:"rgb(76, 175, 80)"} },
        warning: { key: 'warning', checked: true, count: 0, label: 'Warning', checkboxStyle:{color:"rgb(251, 192, 45)"} },
        error: { key: 'error', checked: true, count: 0, label: 'Error', checkboxStyle:{color:"rgb(229, 115, 115)"} }
    };
    const [filterState, setState] = useState(defaultState);

    useEffect(() => {
        setState(defaultState);
    }, [sessionData.id]);

    useEffect(() => {
        let curState = _.extend({}, filterState);
        _.keys(sessionCounts).forEach(k => {
            if(curState[k]) {
                curState[k]['count'] = sessionCounts[k];
            }
        })
        setState(curState);
    }, [sessionData.status, sessionCounts])

    const handleFilterChange = (event) => {
        let f = _.extend({}, filterState);
        f[event.target.name]['checked'] = event.target.checked;
        setState(f);
        let checkFilters = _.chain(filterState).filter({checked: true}).map((v, k) => v.key.toString()).value();
        if(checkFilters.length > 0) {
            onSetFilter([["status", "in", checkFilters]]);
        } else {
            onSetFilter([["status", "in", _.chain(filterState).map((v, k) => v.key.toString()).value()]])
        }
    };

    const getCheckBoxLabel = (v, k) => {
        return (
            <Box component="span" mr={2} key={k}>
                {v.label}
                &nbsp;{sessionCounts && <Box component="span" fontWeight={700}
                borderRadius="40%"
                bgcolor="grey.500"
                p="4px" px="6px"
                fontSize="10px" color="white">{v.count}</Box>}
             </Box>);
    }

    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{padding: '10px', backgroundColor: '#f5f5f5'}}>
            <Grid item>
                <Typography variant="button" display="inline">Filters: &nbsp;&nbsp;</Typography>
                {
                    _.map(filterState, (val, key) => {
                        if(val.count > 0) {
                            return (
                                <FormControlLabel color="orange" key={key}
                                    control={<Checkbox style={val.checkboxStyle} checked={val.checked} onChange={handleFilterChange} name={key} key={key} />} 
                                    label={getCheckBoxLabel(val, key)} />
                            )
                        } else {
                            return null;
                        }
                    })
                }
            </Grid>
        </Grid>
    )
}