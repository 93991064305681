import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export default function Copyright() {
    return (
      <Typography variant="body2">
        {'Copyright © '}
        <Link color="inherit" href="https://firescraper.com/">
          FireScraper
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }