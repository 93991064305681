
import { Alert, AlertTitle } from '@material-ui/lab';
import SessionStatusIcon from '../../../../components/SessionStatusIcon/SessionStatusIcon';
import { SESSION_STATUS } from '../../../../enums';


import DownloadFilesButton from "./DownloadFilesButtons";


export default function SessionAlert({sessionData, sessionCounts}) {
    switch(sessionData.status) {
        case SESSION_STATUS.DONE:
            return (
                <Alert icon={<SessionStatusIcon status={ sessionData.status } />} severity="success">
                    <AlertTitle>Done scraping!</AlertTitle>
                    <span>{sessionCounts.total}</span> URLs scraped. It took <strong> {sessionData.duration}</strong>.
                    <DownloadFilesButton sessionData={sessionData} sessionCounts={sessionCounts} />
                </Alert>
            );
        case SESSION_STATUS.ABORTED:
                return (
                    <Alert icon={<SessionStatusIcon status={ sessionData.status } />} severity="warning">
                        <AlertTitle fontSize={25}>Done Scraping - Session was aborted</AlertTitle>
                        <span>{sessionCounts.total}</span> URLs scraped.
                        <DownloadFilesButton sessionData={sessionData} sessionCounts={sessionCounts} />
                    </Alert>
                );
        case SESSION_STATUS.IN_PROGRESS:
            return (
                <Alert icon={<SessionStatusIcon status={ sessionData.status } size={40} />} severity="info">
                    <AlertTitle style={{fontSize: "20px"}}>Scraping in progress</AlertTitle>
                    <p><strong>{ sessionCounts.total }</strong> URLs scraped.</p>
                </Alert>
            );
        case SESSION_STATUS.ERROR:
        case SESSION_STATUS.DONE_WITH_ERROR:
            return (
                <Alert icon={<SessionStatusIcon status={ sessionData.status } />} severity="warning">
                    <AlertTitle fontSize={25}>Done Scraping - Finished with an error</AlertTitle>
                    <span>{sessionCounts.total}</span> URLs scraped. It took <strong> {sessionData.duration}</strong>.
                    <DownloadFilesButton sessionData={sessionData} sessionCounts={sessionCounts} />
                </Alert>
            );
            case SESSION_STATUS.PAUSED:
                return (
                    <Alert icon={<SessionStatusIcon status={ sessionData.status } />} severity="info">
                        <AlertTitle fontSize={25}>Session is paused...</AlertTitle>
                        <span>{sessionCounts.total}</span> URLs scraped.
                    </Alert>
                );
        default:
            return (
                <Alert icon={<SessionStatusIcon status={ sessionData.status } />} severity="error">
                    <AlertTitle>An Error occurred while scraping. You got {sessionData.status}</AlertTitle>
                    This is an error alert — <strong>check it out!</strong>
                </Alert>
            );
    }
}
