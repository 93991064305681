import { useEffect, useState, useContext } from 'react';
import {  firestore, auth, firefunctions } from '../config/firebase';
import { UserContext } from '../providers/UserProvider';
import _ from 'lodash';

export default function usePagedSessionLogs(sessionId, filters, pagerState) {
    const currentUser = useContext(UserContext);
    const ref = firestore.collection('users').doc(currentUser.uid).collection('sessions').doc(sessionId).collection('logs');
    const defaultState = { error: null, data: null }
    const [data, setData] = useState(defaultState);
    const [prevData, setPrevData] = useState([]);
    const [dataSourceInfo, setDataSourceInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setData(defaultState);
        var itemsRef = ref;
        _.each(filters, (filter) => {
            itemsRef = itemsRef.where(...filter);
        })

        itemsRef = itemsRef.orderBy("dateCreated").orderBy("url");

        if(pagerState) {
            if(pagerState.lastAction === "next") {  //clicked next
                itemsRef = itemsRef
                            .startAfter(_.last(prevData).dateCreated, _.last(prevData).url)
                            .limit(pagerState.pageSize);
            } 
            else if(pagerState.lastAction === "prev") { //clicked prev
                itemsRef = itemsRef
                            .endBefore(_.first(prevData).dateCreated, _.first(prevData).url)
                            .limit(pagerState.pageSize);
            } else {
                itemsRef = itemsRef.limit(pagerState.pageSize);
            }
        }

        var subscriber = itemsRef.onSnapshot((querySnapshot) => {
            let dataArr = [];
            querySnapshot.forEach((doc) => {
                dataArr.push(_.extend({}, { id: doc.id }, doc.data()));
            });

            setDataSourceInfo({
                hasNextPage: dataArr.length == pagerState.pageSize,
                hasPrevPage: pagerState.currentPage > 1
            })
            setPrevData(dataArr);
            setData({
                error: null,
                data: dataArr
            });
            setIsLoading(false);
        })
        return() => { subscriber(); }
    }, [sessionId, pagerState, filters])


    return [data.data, isLoading, dataSourceInfo, data.error];
}
