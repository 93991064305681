import React, { useState, useEffect } from "react";
import { useUserData } from '../../hooks';
import BuyCreditButton from '../BuyCredit/BuyCreditButton';
import { Box } from '@material-ui/core';
import deepOrange from '@material-ui/core/colors/deepOrange';


export default function UsageDefaultWarning() {
    const [userData, userDataIsLoading] = useUserData();

    const [warningIsDisplayed, setWarningIsDisplayed] = useState(null);
    
    const style = {margin: "-24px -24px 0 -24px", borderRadius: "0px", color: "#FFF"};

    const getBgColor = () => {
        if(userData) {
            var x = (900 - (userData.usageLeft < 0 ? 0 : userData.usageLeft));
            return deepOrange[(x/100).toFixed()*100];
        }
        return null;
    }
    

    useEffect(()=> {
        if(!userData) { return false; }
        setWarningIsDisplayed(userData.usageLeft <= 500);
    }, [userData]);

    return (
        <>
        { warningIsDisplayed && 
            <Box display="flex" flexDirection="row"  bgcolor={getBgColor()} px={2} py={1} style={style} alignContent="center">
                <Box flexGrow={1} fontSize={16} >
                <strong>WARNING: </strong> — You have <strong>{userData.usageLeft > 0 ? userData.usageLeft : 0 } units</strong> of your available scrape capacity.
                </Box>
                <Box>
                    <BuyCreditButton />
                </Box>
            </Box>
        }
        </>
    )
}