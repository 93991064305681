
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import {SESSION_LOG_STATUS } from '../../../../enums';
import { Chip } from '@material-ui/core';

interface Props {
    status: SESSION_LOG_STATUS 
}

export default function SessionLogStatusBadge({ status }: Props) {
    switch(status) {
        case SESSION_LOG_STATUS.SUCCESS:
           return ( 
                <Chip size="small" icon={<CheckCircleIcon style={{color: "white" }}/>} label="success" style={{ backgroundColor: green[500], color: "white",  fontWeight:600 }} 
                />
            );
        case SESSION_LOG_STATUS.WARNING:
            return ( 
                <Chip size="small" icon={<ErrorIcon style={{color: "white" }} />} label="warning" style={{ backgroundColor: yellow[700], color: "white",  fontWeight:600 }} 
                />
            );
        case SESSION_LOG_STATUS.ERROR:
            return ( 
                <Chip size="small" icon={<CancelIcon style={{color: "white" }} />} label="error" style={{ backgroundColor: red[300], color: "white",  fontWeight:600 }} 
                />
            );
        case SESSION_LOG_STATUS.INFO:
            return ( 
                <Chip size="small" icon={<HelpIcon style={{color: "white" }} />} label="info" style={{ backgroundColor: blue[700], color: "white",  fontWeight:600 }} 
                />
            );
        default:
            return ( 
                <Chip size="small" icon={<HelpIcon style={{color: "white" }} />} label="info" style={{ backgroundColor: grey[700], color: "white",  fontWeight:600 }} 
                />
            );
    }
}
