
import {  firestore } from '../config/firebase';
import { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { UserContext } from '../providers/UserProvider';

export default function useSessionsList(orderBy = "dateCreated", count=-1) {
    const currentUser = useContext(UserContext);
    let ref = firestore.collection('users').doc(currentUser.uid).collection('sessions').orderBy(orderBy, 'desc');

    if(count !== -1) {
        ref = ref.limit(count);
    }

    const defaultState = { isLoading: true, error: null, data: [] }
    const [data, setData] = useState(defaultState);

    useEffect(() => {
        setData(defaultState);
        var subscriber = ref.onSnapshot((querySnapshot) => {
                let dataArr = [];
                querySnapshot.forEach((doc) => {
                    dataArr.push(_.extend({}, { id: doc.id }, doc.data()));
                });
                setData({
                    isLoading: false,
                    error: null,
                    data: dataArr
                })
            });
        return () => { subscriber(); }
    }, [currentUser.uid, orderBy]);

    return [data.data, data.isLoading, data.error];
};