export default () => {
    return (
        <div className="p-5">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="pb-3">Terms of Use</h2>
                    <p>This web page represents our Terms of Use and Sale ("Agreement") regarding our website, located at FireScraper.com, and the tools we provide you (the "Website" or the "Service"). It was last posted on 24 January 2021. The terms, "we" and "our" as used in this Agreement refer to FireScraper.</p>
                    <p>We may amend this Agreement at any time by posting the amended terms on our Website. We may or may not post notices on the homepage of our Website when such changes occur.</p>
                    <p>We refer to this Agreement, our Privacy Policy accessible at <a href="https://firescraper.com/privacy-policy">https://firescraper.com/privacy-policy</a>, and any other terms, rules, or guidelines on our Website collectively as our "Legal Terms." You explicitly and implicitly agree to be bound by our Legal Terms each time you access our Website. If you do not wish to be so bound, please do not use or access our Website.</p>
                    <p className="h5 text-muted pt-3 pb-3"><strong>Limited License</strong></p>
                    <p>FireScraper grants you a non-exclusive, non-transferable, revocable license to access and use our Website in order for you to make purchases of electronic documents and related services through our Website, strictly in accordance with our Legal Terms.</p>
                    <p className="h5 text-muted pt-3 pb-3"><strong>Copyrights and Trademarks</strong></p>
                    <p>Unless otherwise noted, all materials including without limitation, logos, brand names, images, designs, photographs, video clips and written and other materials that appear as part of our Website are copyrights, trademarks, service marks, trade dress and/or other intellectual property whether registered or unregistered ("Intellectual Property") owned, controlled or licensed by FireScraper. Our Website as a whole is protected by copyright and trade dress. Nothing on our Website should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Intellectual Property displayed or used on our Website, without the prior written permission of the Intellectual Property owner. FireScraper aggressively enforces its intellectual property rights to the fullest extent of the law. The names and logos of FireScraper, may not be used in any way, including in advertising or publicity pertaining to distribution of materials on our Website, without prior, written permission from FireScraper. FireScraper prohibits use of any logo of FireScraper or any of its affiliates as part of a link to or from any Website unless FireScraper approves such link in advance and in writing. Fair use of FireScraper’s Intellectual Property requires proper acknowledgment. Other product and company names mentioned in our Website may be the Intellectual Property of their respective owners.</p>
                    <p className="h5 text-muted pt-3 pb-3"><strong>Links to Third-Party Websites</strong></p>
                    <p>Our Website may contain links to Websites owned or operated by parties other than FireScraper. Such links are provided for your reference only. FireScraper does not monitor or control outside Websites and is not responsible for their content. FireScraper’s inclusion of links to an outside Website does not imply any endorsement of the material on our Website or, unless expressly disclosed otherwise, any sponsorship, affiliation or association with its owner, operator or sponsor, nor does FireScraper’ inclusion of the links imply that FireScraper is authorized to use any trade name, trademark, logo, legal or official seal, or copyrighted symbol that may be reflected in the linked Website.</p>
                    <p className="h5 text-muted pt-3 pb-3"><strong>Content Disclaimer</strong></p>
                    <p>Postings on our Website are made at such times as FireScraper determines in its discretion. You should not assume that the information contained on our Website has been updated or otherwise contains current information. FireScraper does not review past postings to determine whether they remain accurate and information contained in such postings may have been superseded. THE INFORMATION AND MATERIALS IN OUR WEBSITE ARE PROVIDED FOR YOUR REVIEW IN ACCORDANCE WITH THE NOTICES, TERMS AND CONDITIONS SET FORTH HEREIN. THESE MATERIALS ARE NOT GUARANTEED OR REPRESENTED TO BE COMPLETE, CORRECT OR UP TO DATE. THESE MATERIALS MAY BE CHANGED FROM TIME TO TIME WITHOUT NOTICE.</p>
                    <p className="h5 text-muted pt-3 pb-3"><strong>Contracts and Legal Advice Disclaimer</strong></p>
                    <p>You understand that we offer legal contracts ("Contracts"), as created and/or verified by attorneys or paralegals for sale through our Website. You acknowledge that we are not attorneys or paralegals nor do we offer legal advice. You acknowledge that we do not endorse any specific attorney or paralegal or any Contracts as being better than another. We do not otherwise guarantee the legal accuracy or applicability of any Contracts for your legal needs. You will at all times look to any attorney or paralegal that you select for services as to any legal claims related to such services.</p>
                    <p className="h5 text-muted pt-3 pb-3"><strong>Refunds</strong></p>
                    <p>We issue refunds for Contracts within 7 days of the original purchase of the Contract.</p>
                    <p className="h5 text-muted pt-3 pb-3"><strong>No Warranties; Exclusion of Liability; Indemnification</strong></p>
                    <p>OUR WEBSITE IS OPERATED BY FireScraper ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, FireScraper SPECIFICALLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT FOR OUR WEBSITE AND ANY CONTRACTS AND SERVICES YOU PURCHASE THROUGH IT. FireScraper SHALL NOT HAVE ANY LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENT OF OUR WEBSITE, FOR CONTRACTS OR SERVICES SOLD THROUGH OUR WEBSITE, FOR YOUR ACTION OR INACTION IN CONNECTION WITH OUR WEBSITE OR FOR ANY DAMAGE TO YOUR COMPUTER OR DATA OR ANY OTHER DAMAGE YOU MAY INCUR IN CONNECTION WITH OUR WEBSITE. YOUR USE OF OUR WEBSITE AND ANY CONTRACTS OR SERVICES ARE AT YOUR OWN RISK. IN NO EVENT SHALL EITHER FireScraper OR THEIR AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OUR WEBSITE, CONTRACTS AND SERVICES PURCHASED THROUGH OUR WEBSITE, THE DELAY OR INABILITY TO USE OUR WEBSITE OR OTHERWISE ARISING IN CONNECTION WITH OUR WEBSITE, CONTRACTS OR RELATED SERVICES, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES. IN NO EVENT SHALL FireScraper’ LIABILITY FOR ANY DAMAGE CLAIM EXCEED THE AMOUNT PAID BY YOU TO FireScraper FOR THE TRANSACTION GIVING RISE TO SUCH DAMAGE CLAIM.</p>
                    <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p>
                    <p>WITHOUT LIMITING THE FOREGOING, FireScraper DO NOT REPRESENT OR WARRANT THAT THE INFORMATION ON THE WEBITE IS ACCURATE, COMPLETE, RELIABLE, USEFUL, TIMELY OR CURRENT OR THAT OUR WEBSITE WILL OPERATE WITHOUT INTERRUPTION OR ERROR.</p>
                    <p>YOU AGREE THAT ALL TIMES, YOU WILL LOOK TO ATTORNEYS FROM WHOM YOU PURCHASE SERVICES FOR ANY CLAIMS OF ANY NATURE, INCLUDING LOSS, DAMAGE, OR WARRANTY. FireScraper AND THEIR RESPECTIVE AFFILIATES MAKE NO REPRESENTATION OR GUARANTEES ABOUT ANY CONTRACTS AND SERVICES OFFERED THROUGH OUR WEBSITE.</p>
                    <p>FireScraper MAKES NO REPRESENTATION THAT CONTENT PROVIDED ON OUR WEBSITE, CONTRACTS, OR RELATED SERVICES ARE APPLICABLE OR APPROPRIATE FOR USE IN ALL JURISDICTIONS.</p>
                    <p className="h5 text-muted pt-3 pb-3"><strong>Indemnification</strong></p>
                    
                    <p>You agree to defend, indemnify and hold FireScraper harmless from and against any and all claims, damages, costs and expenses, including attorneys' fees, arising from or related to your use of our Website or any Contracts or Services you purchase through it.</p>
                </div>
            </div>
        </div>
    )
}