import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import _ from 'lodash';
import { useState, } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';

export default function LogsPager({onPageStateChange, dataSourceInfo}) {
    const defaultPagerState = { currentPage: 1, pageSize:50, previousPage: null, lastAction: null };
    const [pagerState, setPagerState] = useState(defaultPagerState);

    const onPrevClick = () => {
        setPagerState(_.extend({}, pagerState, { previousPage: pagerState.currentPage, lastAction: "prev", currentPage :(pagerState.currentPage - 1 )}));
        onPageStateChange(pagerState);
    }

    const onNextClick = () => {
        const newPagerState = _.extend({}, pagerState, { previousPage: pagerState.currentPage, lastAction:"next", currentPage: (pagerState.currentPage + 1 )});
        setPagerState(newPagerState);
        onPageStateChange(newPagerState);
    }

    const onPageSizeChange = (event) => {
        const newPagerState = _.extend({}, defaultPagerState, { pageSize: event.target.value });
        setPagerState(newPagerState);
        onPageStateChange(newPagerState);
    }

    return (
        <>
            <Box p={1} width={1} display="flex" flexDirection="row" alignItems="center">
                <Box px={2} flexGrow={1}>
                    Page size&nbsp;:&nbsp;
                    <FormControl >
                        <Select
                        style={{marginTop: "0px"}}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={pagerState.pageSize}
                        onChange={onPageSizeChange}
                        label="Age">
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box pr={1}>
                    <span>Page {pagerState.currentPage} </span>
                    <ButtonGroup color="secondary" size="small"  aria-label="outlined primary button group">
                        <Button disabled={!dataSourceInfo.hasPrevPage} onClick={onPrevClick}>Prev Page</Button>
                        <Button disabled={!dataSourceInfo.hasNextPage} onClick={onNextClick}>Next Page</Button>
                    </ButtonGroup>
                </Box>
            </Box>
        </>
    )
}