import { useState } from "react";
import { Box, IconButton, Link } from '@material-ui/core';
import SessionLogStatusBadge from './SessionLogStatusBadge';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SessionLogDetailsModal from './SessionLogDetailsModal';
import SubjectIcon from '@material-ui/icons/Subject';

export default function LogsList({ sessionLogs }) {

    const [openLogDetails, setOpenLogDetails] = useState(false);
    const [modalLogDetails, setModalLogDetails] = useState(null);
    function handleShowLogDetails(logDetails) {
        if(logDetails) {
            setModalLogDetails(logDetails);
            setOpenLogDetails(true);
        }
    }

    function handleCloseLogDetails() {
        setOpenLogDetails(false);
        setModalLogDetails(null);
    }

    return (
        <> 
            { modalLogDetails && <SessionLogDetailsModal open={openLogDetails} details={modalLogDetails} onClose={handleCloseLogDetails} /> }        
            <Table size="small" style={{ width: "100%", overflowWrap: "anywhere"}}>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>URL</TableCell>
                        <TableCell style={{width: "120px"}}>Word Count</TableCell>
                        <TableCell style={{width: "120px"}}>Internal URLs</TableCell>
                        <TableCell style={{width: "120px"}}>Depth</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessionLogs.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                {row.status !== "erro" && 
                                <IconButton color="primary" size="small" component="span" onClick={() => { handleShowLogDetails(row) }}>
                                    <SubjectIcon />
                                </IconButton> }
                            </TableCell>
                            <TableCell>
                                <SessionLogStatusBadge status={row.status} />
                            </TableCell>
                            <TableCell> 
                                <Link href={row.url} variant="body2" target="_blank">{ row.url }</Link>
                                {row.status !== "success" && <Box m={0} mt="5px" component="p" color="grey.500" fontSize={12}>{row.statusMessage}</Box> }
                             </TableCell>
                             <TableCell> { row.num_of_words } </TableCell>
                             <TableCell> { row.internal_urls_count } </TableCell>
                            <TableCell> { row.depth } </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}