


export enum SESSION_LOG_STATUS {
    SUCCESS = "success",
    ERROR = "error",
    WARNING = "warning",
    INFO = "info"
}

export enum SESSION_STATUS {
    PENDING_START = "pending-start",
    NOT_STARTED = "not-started",
    STARTED = "started",
    PAUSED = "paused",
    IN_PROGRESS = "in-progress",
    FINALIZING = "finalizing-files",
    DONE = "done",
    ABORTED = "aborted",
    SERVER_FAILED = "server-failed",
    ERROR = "error",
    MONTHLY_LIMIT_REACHED_ERROR = "limit-reached",
    DRAFT = "draft",
    DONE_WITH_ERROR = "done-with-error"
}