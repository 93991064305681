import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useUserData} from '../../hooks';
import { Box, Avatar, ListItem, Divider, ListItemText, ListItemAvatar } from '@material-ui/core';
import BuyCreditButton from '../../components/BuyCredit/BuyCreditButton';
import { auth } from '../../config/firebase';
import { UserContext } from '../../providers/UserProvider';
import PersonIcon from '@material-ui/icons/Person';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import ContactUsDialog from '../../components/ContactUsDialog/ContactUsDialog';
import { useHistory } from 'react-router-dom'

function AdminTopMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userData, userDataLoading] = useUserData();
    const user = useContext(UserContext);
    const [contactDialogIsOpen, setContactDialogIsOpen] = React.useState(false);
    const history = useHistory();

    const showMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const hideMenu = () => {
        setAnchorEl(null);
    };

    const SignOut = () => {
        auth.signOut().then(()=> {
          history.push("/auth/login");
        }).catch((error) => {
          //console.log(error.message)
        })
    };

    const onContactUsClick = () => {
        setContactDialogIsOpen(true);
      }
    
      const onContactDialogClose = () => {
        setContactDialogIsOpen(false);
      }

    const getUserAvatar = function() {
        if(user.photoURL) {
            return (
                <Avatar alt={user.displayName} src={user.photoURL} />
            );
        } else {
            <Avatar alt={user.displayName}><PersonIcon /></Avatar>
        }
    }

    return (
        <React.Fragment>
            <ContactUsDialog open={contactDialogIsOpen} dialogType="add" onClose={onContactDialogClose} />
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={showMenu}>
            Account
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={hideMenu}
          >
            <MenuItem onClick={hideMenu}>
                <ListItemAvatar>
                {getUserAvatar()}
                </ListItemAvatar>
                <ListItemText primary={user.displayName} secondary="" />
            </MenuItem>
            <Divider component="li" />
            {!userDataLoading && <MenuItem onClick={hideMenu}>
                <ListItemAvatar>
                <Avatar><LocalGasStationIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary="Usage Left" secondary={"You have " + userData.usageLeft + " credits left "} />
                
            </MenuItem>}
            <Divider component="li" />
            <MenuItem>
                <Box width={1} textAlign="center">
                    <BuyCreditButton />
                </Box>
            </MenuItem>
            <Divider component="li" />
            <MenuItem onClick={onContactUsClick}>Contact Us</MenuItem>
          </Menu>
          <Button variant="contained" color="secondary" onClick={SignOut}>Sign Out</Button>
        </React.Fragment>
    );
  

}

export default AdminTopMenu;