import { Dialog, DialogContentText, DialogTitle, IconButton, Box, DialogContent, Link } from "@material-ui/core";
import SessionLogStatusBadge from './SessionLogStatusBadge';
import CloseIcon from '@material-ui/icons/Close';

export default function SessionLogDetailsModal({
    details,
    open = false,
    onClose = () => {}
}) {
    const handleClose = () => {
        onClose();
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1}>
                            <Box>{details.url} 
                            </Box>
                            {details.title && <Box style={{fontSize: "0.6em", color: "#909090"}}>{details.title}</Box>}
                        </Box>
                        <Box>
                        <IconButton aria-label="close" onClick={handleClose} style={{position: "absolute", top:"6px", right:"10px"}}>
                            <CloseIcon size="sm" />
                        </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent mb={1}>
                    <Box display="flex" flexDirection="row" fontWeight="fontWeightMedium" fontSize={12} color="grey.700" mb={2}>
                        <Box pr={1} mr={1} borderRight={1} borderColor="grey.500">{details.num_of_words} words</Box>
                        <Box pr={1} mr={1} borderRight={1} borderColor="grey.500">{details.internal_urls_count} internal urls</Box>
                        <Box pr={1} mr={1} borderRight={1} borderColor="grey.500">{details.external_urls_count} external urls</Box>
                        <Box flexGrow={1} textAlign="right"><SessionLogStatusBadge status={details.status} /></Box>
                    </Box>
                    <DialogContentText>{details.text}</DialogContentText>    
                </DialogContent>
            </Dialog>
        </>
    )
}