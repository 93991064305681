import { Button } from '@material-ui/core';
import { useState } from 'react';
import BuyCreditDialog from './BuyCreditDialog';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const STRIPE_PUBLISHABLE = process.env.REACT_APP_STRIPE_PUBLISHABLE;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE);

export default function BuyCreditButton() {

    const [creditDialogIsOpen, setIsCreditDialogIsOpen] = useState(false);

    const openCreditDialog = () => {
        setIsCreditDialogIsOpen(true);
    }

    const closeCreditDialog = () => {
        setIsCreditDialogIsOpen(false);
    }

    return (
        <>
            <Elements stripe={stripePromise}>
                <Button variant="contained" color="secondary" onClick={openCreditDialog}>Buy More Credits</Button>
                <div>
                    <BuyCreditDialog open={creditDialogIsOpen} onClose={closeCreditDialog} />
                </div>
            </Elements>
        </>
        
    )
}