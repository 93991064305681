

import './App.css';
import { BrowserRouter, Switch, useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import AppLayout from './layouts/AppLayout/index';
import AuthLayout from './layouts/AuthLayout';
import PublicLayout from './layouts/PublicLayout';

import LoginPage from './auth/pages/Login/Login';
import SessionDetailsPage from './app/pages/SessionDetails/index';
import DashboardPage from './app/pages/Dashboard/Dashboard';
import HomePage from './public/pages/HomePage/HomePage';
import PricingPage from './public/pages/Pricing/Pricing';
import PrivacyPolicyPage from './public/pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUsePage from './public/pages/TermsOfUse/TermsOfUse';
import NotificationsPage from './pages/Notifications/Notifications';

import AdminMainPage from './admin/pages/MainPage/MainPage';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import AdminRoute from './components/AdminRoute';

import UserProvider from "./providers/UserProvider";
import { deepOrange, blueGrey } from '@material-ui/core/colors';
import {Helmet} from "react-helmet";

const theme = createTheme({
  status: {
    danger: "orange",
  },
  palette: {
    primary: blueGrey,
    secondary: deepOrange
  }
});

export default function App() {
    let history = useHistory();
    return (
      <ThemeProvider theme={theme}>
          <UserProvider>
          <Helmet>
                <meta charSet="utf-8" />
                <title>FireScraper - A Tool for extracting articles from web pages</title>
                <link rel="canonical" href="https://firescraper.com" />
                <meta name="description" content="Firescraper is a tool for extracting articles from multiple web pages." />
                <meta name="keywords" content="firescraper,article,extractor,topic,modelling,textmining,scraping" />
            </Helmet>
            <BrowserRouter history={history}>
                <Switch>
                <AdminRoute path = '/admin/bravo' exact component={AdminMainPage} pageTitle="Admin Main Page" />

                  <PrivateRoute path = '/app/dashboard' exact component={DashboardPage} layout={AppLayout} pageTitle="Dashboard" />
                  <PrivateRoute path = '/app/sessions/:sessionId' exact component={SessionDetailsPage} layout={AppLayout} pageTitle="Session details" />
                  
                  <PublicRoute path = '/notifications/:notificationType' exact component={NotificationsPage} layout={AuthLayout} pageTitle="Notifications" />
                  <PublicRoute path = 'app/notifications/:notificationType' exact component={NotificationsPage} layout={AuthLayout} pageTitle="Notifications" />
                  <PublicRoute path = '/auth/login' exact component={LoginPage} layout={AuthLayout} pageTitle="Login" redirectLoggedIn={true} />
                  <PublicRoute path = '/privacypolicy' exact component={PrivacyPolicyPage} layout={PublicLayout} pageTitle="Privacy policy" />
                  <PublicRoute path = '/termsofuse' exact component={TermsOfUsePage} layout={PublicLayout} pageTitle="Terms of use" />
                  <PublicRoute path = '/pricing' exact component={PricingPage} layout={PublicLayout} pageTitle="Pricing" />
                  <PublicRoute path = '/' component={HomePage} layout={PublicLayout} pageTitle="Home" />
                </Switch>
              </BrowserRouter>
          </UserProvider>
        </ThemeProvider>
    );
}

