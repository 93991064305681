import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Copyright from '../components/Copyright/Copyright';
import MainLogo from '../components/MainLogo';
import { Typography } from '@material-ui/core';
import grey from "@material-ui/core/colors/grey";

export default function AuthLayout({children}) {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box display="flex" flexDirection="column" mt={4}>
        <Box textAlign="center">
          <Typography variant="h6" color="inherit" noWrap>
            <MainLogo />
          </Typography>
        </Box>
        <Box borderColor={grey[300]} border={1} mt={2} borderRadius={4}>
          {children}
        </Box>
        <Box mt={2} textAlign="center">
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
}