import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {  firefunctions } from '../../config/firebase';
import { UserContext } from '../../providers/UserProvider';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

function ContactUsDialog({
    open = false,
    onClose = () => {}
}) {
    const currentUser = useContext(UserContext);

    let defaultState = {
        name: currentUser ? currentUser.displayName : '',
        email: currentUser ? currentUser.email : '',
        message: ""
    };
    const [state, setState] = useState(defaultState);

    const [isSaving, setIsSaving] = useState(false);

    const handleClose = () => {
        onClose();
    };

    const  handleChange = (e) => {
        let s = _.extend({}, state, {[e.target.name]: e.target.value})
        setState(s);
    }

    const handleSubmit = () => {
        setIsSaving(true);
        var dataToSend = _.extend({}, state);
        dataToSend["url"] = window.location.href;
        var functionRef = firefunctions.httpsCallable('onContactUs');
        functionRef(dataToSend).then((msg) => {
            setState(defaultState);
            setIsSaving(false);
            handleClose();
        });
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{backgroundColor: "rgb(240, 240, 240)"}}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1}>Contact Us </Box>
                        <Box>
                        <IconButton aria-label="close" onClick={handleClose} style={{position: "absolute", top:"10px", right:"10px"}}>
                            <CloseIcon size="sm" />
                        </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth={true} margin="normal">
                        <TextField
                            autoFocus
                            margin="normal"
                            name="name"
                            label="Your name"
                            helperText="Please enter your fullname"
                            type="text"
                            variant="outlined"
                            disabled={currentUser !== undefined}
                            value={state.name}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                        <TextField
                            name="emailAddress"
                            label="Email Address"
                            helperText="Enter you email addresss"
                            variant="outlined"
                            disabled={currentUser !== undefined}
                            type="email"
                            value={state.email}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                        <TextField
                            name="message"
                            label="Enter your message/question"
                            helperText="Enter your message, question or comments"
                            multiline
                            type="text"
                            rows={4}
                            value={state.message}
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </FormControl>
                </DialogContent>
                    <DialogActions mt={2} pt={2} style={{backgroundColor: "rgb(240, 240, 240)", paddingTop:"10px"}}>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} variant="contained" color="secondary" disabled={isSaving}>
                            { isSaving && (<CircularProgress  style={{width: "20px", height: "20px", "margin-left": "8px"}} />)}
                            Send
                        </Button>
                    </DialogActions>
            </Dialog>
        </div>
    );
}

export default ContactUsDialog;