import React, { createContext } from "react";
import { useAuth } from '../hooks';

export const UserContext = createContext({user: null})
export default function UserProvider(props) {
  const [user, isLoaded] = useAuth();

  if(isLoaded) {
    return (
      <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
    )
  } else {
    return null;
  }
}