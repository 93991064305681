import { useEffect, useState, useContext } from 'react';
import {  firestore, auth, firefunctions } from '../config/firebase';
import { UserContext } from '../providers/UserProvider';
import _ from 'lodash';


export default function useSessionCounts(sessionId) {
    const currentUser = useContext(UserContext);
    const ref = firestore.collection('users').doc(currentUser.uid).collection('sessions').doc(sessionId).collection("stats").doc("counts")
    const defaultState = { isLoading: true, error: null, data: {success: 0, error: 0, warning: 0, total: 0} }
    const [data, setData] = useState(defaultState);

    useEffect(() => {
        setData(defaultState);
        var subscriber = ref.onSnapshot((doc) => {
            setData({
                isLoading: false,
                error: null,
                data: _.extend({}, { id: doc.id }, doc.data())
            })
        })
        return() => { subscriber(); }
    }, [sessionId]);

    return [data.data, data.isLoading, data.error];
}