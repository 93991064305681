import firebase from 'firebase';

const config2 = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
}

firebase.initializeApp(config2);
firebase.analytics();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const auth = firebase.auth();

var _db = firebase.database();
var _firestore = firebase.firestore();
var _firefunctions = firebase.functions();
if (window.location.hostname === "localhost") {
    _db.useEmulator("localhost", 9001);
    _firestore.useEmulator("localhost", 8080);
    _firefunctions.useEmulator("localhost", 5001);
}
export const db = _db;
export const firestore = _firestore;
export const firefunctions = _firefunctions;
export const firestorage = firebase.storage();
export default firebase;

