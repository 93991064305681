import { useEffect, useState, useContext } from 'react';
import {  firestore, auth, firefunctions } from '../config/firebase';
import _ from 'lodash';

export function useFireCallable (name, props) {
    const [results, setResults] = useState([]);
    const [loading, setIsLoading] = useState(false);

    const loadData = async () => {
        setIsLoading(true);
        setResults([]);
        var functionRef = firefunctions.httpsCallable(name);
        const resp = await functionRef(props);
        setResults(resp.data);
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [name])

    return [results, loading];
}



