import { Box } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';


interface Props {
    loadingMessage?: string
}

export const LoadSpinner = ({ loadingMessage="Loading..." }: Props) => {
    return (
        <Box width={1} display="flex" textAlign="center" justifyContent="center" flexDirection="column" p={3}>
            <Box textAlign="center"><CircularProgress color="primary"></CircularProgress></Box>
            {loadingMessage && <Box textAlign="center">{loadingMessage}</Box>}
        </Box>
    )
}