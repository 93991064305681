import { useState } from "react";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { firefunctions } from "../../../../config/firebase";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, IconButton } from "@material-ui/core";
import AddEditSessionDialog, { DialogType } from "../../../../components/AddEditSessionDialog/AddEditSessionDialog";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Swal from 'sweetalert2';
import { SESSION_STATUS } from '../../../../enums';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Session } from '../../../../types/index';

interface Props {
  sessionData: Session;
}

export default function SessionActions({ sessionData }: Props) {
  const [sessDialogIsOpen, setSessDialogIsOpen] = useState(false);
  const [sessDialogType, setSessDialogType] = useState(DialogType.VIEW);

  let history = useHistory();

  const onCopySessionClick = () => {
    setSessDialogType(DialogType.COPY);
    setSessDialogIsOpen(true);
  };

  const onViewSessionDetailsClick = () => {
    setSessDialogType(DialogType.VIEW);
    setSessDialogIsOpen(true);
  };

  const onSessionDeleteClick = () => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          var functionRef = firefunctions.httpsCallable('onSessionDelete');
          functionRef({ sessionId: sessionData.id }).then((msg) => {
            Swal.fire(
              'Deleted!',
              'Session has been deleted.',
              'success'
            );
          }, (err) => {
            console.error("Error removing document: ", err);
          });
        }
      })
  };

  const [isPausePlayLoading, setIsPausePlayLoading] = useState<boolean>(false);
  const onPausePlayClick = () => {
    if(!isPausePlayLoading && (sessionData.status === SESSION_STATUS.PAUSED || sessionData.status === SESSION_STATUS.IN_PROGRESS)) {
      setIsPausePlayLoading(true);
      var functionRef = sessionData.status === SESSION_STATUS.PAUSED ? 
                        firefunctions.httpsCallable('onSessionPlay')  : 
                        firefunctions.httpsCallable('onSessionPause');
      
      functionRef({ sessionId: sessionData.id }).then((msg) => {
      }).finally(()=> { 
        setIsPausePlayLoading(false);
      });
    }
  }

  const [isAborting, setIsAborting] = useState<boolean>(false);
  const onAbortClick = () => {
    setIsAborting(true);
    var functionRef = firefunctions.httpsCallable('onSessionAbort');
    functionRef({ sessionId: sessionData.id }).then((msg) => {
    }).finally(()=> { 
      setIsAborting(false); 
    });;
  }


  const onCloseSessDialog = () => {
    setSessDialogIsOpen(false);
  };

  return (
    <>
      <AddEditSessionDialog sessionDetails={sessionData} dialogType={sessDialogType} open={sessDialogIsOpen} onClose={onCloseSessDialog}/>
      <Box display="flex" flexDirection="row">
        
        <Box mr={1} pr={1}>
      
          <ButtonGroup size="small" aria-label="small outlined button group">
            { (sessionData.status === SESSION_STATUS.IN_PROGRESS || sessionData.status === SESSION_STATUS.PAUSED) && 
              <Tooltip title={sessionData.status === SESSION_STATUS.IN_PROGRESS ? 'Pause - Saves current progress and pauses' : 'Play - Continue running where you left-off'}>
                <Button size="small" variant="contained" onClick={onPausePlayClick}>
                  { !isPausePlayLoading && sessionData.status === SESSION_STATUS.IN_PROGRESS && <PauseCircleFilledIcon /> }
                  {isPausePlayLoading && <CircularProgress size={22}  /> }
                  { !isPausePlayLoading && sessionData.status === SESSION_STATUS.PAUSED && <PlayCircleFilledIcon /> }
                </Button>
              </Tooltip>
            }
          </ButtonGroup>
        </Box>
        <Box mr={1} pr={1} borderRight={1}>
          { (sessionData.status === SESSION_STATUS.IN_PROGRESS || sessionData.status === SESSION_STATUS.PAUSED) && 
            <Tooltip title="Abort - Stop this session where it is and generate the files">
              <Button size="small" onClick={onAbortClick} variant="contained" color="secondary" disabled={isAborting}>
                { isAborting && (<CircularProgress  style={{width: "14px", height: "14px", "marginRight": "6px"}} />)}
                abort
              </Button>
            </Tooltip>
          }
        </Box>
        <Box>
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Tooltip title="Copy Session - Create a new session based details of this one">
              <IconButton onClick={onCopySessionClick}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Session Details - Details of the current session">
              <IconButton onClick={onViewSessionDetailsClick}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Session - Delete all data. Cannot be reverted">
              <IconButton onClick={onSessionDeleteClick}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </Box>
        <Box>
          
        </Box>
      </Box>
    </>
  );
}
