import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firefunctions } from '../../config/firebase';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Session } from '../../types/index';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Swal from 'sweetalert2';
import validator from 'validator';

export enum DialogType { ADD = "add", VIEW = "view", COPY = "copy" }

const FEATURE_MLSCRAPER = process.env.REACT_APP_FEATURE_MLSCRAPER === "true";

interface DialogFormFields {
    listOfIgnoreUrls: string,
    listOfUrls: string,
    maxDepth: number,
    minTextLength: number,
    name: string,
    enforceUniqueTextDownloads: boolean,
    scraperToUse: string
}

interface Props {
    sessionDetails: Session;
    open: boolean;
    dialogType: DialogType;
    onClose?(): void;
}

//dialogType can be add/copy/view
function AddEditSessionDialog({sessionDetails, 
    open = false, 
    dialogType = DialogType.ADD,
    onClose = () => {}
} : Props) {

    let history = useHistory();

    const isLocalHost = window.location.href.includes('localhost')

    let defaultState = {
        name: "",
        maxDepth: 0,
        listOfUrls: !isLocalHost ? "" : "https://www.digitalocean.com/\r\nhttps://platform.sh/\r\nhttps://www.vultr.com/\r\nhttps://www.rackspace.com/\r\nhttps://upcloud.com/",
        listOfIgnoreUrls: "", //"https://aws.amazon.com/",
        minTextLength: 0,
        enforceUniqueTextDownloads: true,
        scraperToUse: "ml",
    };
    const [state, setState] = useState<DialogFormFields>(defaultState);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        if(sessionDetails) {
            setState({
                name: (dialogType === "view" ? sessionDetails.name : ""),
                maxDepth: sessionDetails.maxDepth,
                scraperToUse: sessionDetails.scraperToUse ?? "ml",
                listOfUrls: sessionDetails.listOfUrls ? sessionDetails.listOfUrls.join("\n") : "",
                listOfIgnoreUrls: sessionDetails.listOfIgnoreUrls ? sessionDetails.listOfIgnoreUrls.join("\n") : "",
                minTextLength: sessionDetails.minTextLength,
                enforceUniqueTextDownloads: sessionDetails.enforceUniqueTextDownloads ? sessionDetails.enforceUniqueTextDownloads : false
            })
        }
    }, [sessionDetails, dialogType]);

    const handleClose = () => {
        onClose();
    };

    const  handleChange = (e: any) => {
        let s = _.extend({}, state, {[e.target.name]: e.target.value})
        setState(s);
    }

    const doSave = (dataToSave: Session) => {
        setIsSaving(true);
        var functionRef = firefunctions.httpsCallable('onSessionAdd');
        functionRef(dataToSave).then((msg) => {
            setState(defaultState);
            setIsSaving(false);
            handleClose();
            history.push('/app/sessions/' + msg.data.id)
        }, (err) => {
            setIsSaving(false);
        });
    }

    const handleSaveSession = () => {
        let dataToSave = _.omit(state, 'listOfUrls', 'listOfIgnoreUrls') as Session;
        dataToSave.listOfUrls = state.listOfUrls.split(/\n/).map(v => v.trim()).filter(x=>x.length > 0);

        try {
            dataToSave.listOfIgnoreUrls = state.listOfIgnoreUrls.split(/\n/).map(v=>v.trim()).filter(x=>x.length > 0);
        } catch (err) {
            dataToSave.listOfIgnoreUrls = [];
        }

        const invalidURLs = _.chain(dataToSave.listOfUrls).filter(x => !validator.isURL(x)).value();
        const invalidIgnoreURLs = _.chain(dataToSave.listOfIgnoreUrls).filter(x => !validator.isURL(x)).value();

        //check for duplicate URLs and notify user if any
        if(dataToSave.name.trim().length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Name is a required field',
                // text: 'Each session should have a name',
            });
        }
        else if(dataToSave.listOfUrls.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'List of urls is required',
                text: 'Remember to enter one URL per line',
            });
        }
        else if(invalidURLs.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid URL(s) entered',
                text: 'One of the URLs entered is not a valid URL.',
            });
        }
        else if(invalidIgnoreURLs.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid URL(s) entered',
                text: 'One of the ignore URLs entered is not a valid URL.',
            });
        }
        else if(_.uniq(dataToSave.listOfUrls).length !== dataToSave.listOfUrls.length) {
            Swal.fire({
                title: 'Duplicate URLs',
                icon: 'info',
                text: 'You list of URLs contains duplicate entries. Duplicates will be removed and not processed.',
                showCancelButton: true,
                confirmButtonText: 'Sure, go ahead!'
            }).then((result) => {
                if(result.isConfirmed) {
                    dataToSave.listOfUrls = _.uniq(dataToSave.listOfUrls);
                    doSave(dataToSave);
                }
            })
        } else {
            doSave(dataToSave);
        }

    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{backgroundColor: "rgb(240, 240, 240)"}}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1}>{ dialogType === 'view' ? 'Session Details' : 'Add Session' } </Box>
                        <Box>
                        <IconButton aria-label="close" onClick={handleClose} style={{position: "absolute", top:"10px", right:"10px"}}>
                            <CloseIcon />
                        </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                {isSaving && (<LinearProgress />)}
                <DialogContent>
                    {FEATURE_MLSCRAPER && <FormGroup row>
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={state.scraperToUse === 'ml'}
                                    onChange={handleChange}
                                    value="ml"
                                    name="scraperToUse"
                                />
                            }
                            label={
                                (<Box display="flex" flexDirection="column">
                                <span>Use Topic Modelling Scraper</span>
                                <span style={{fontSize:"0.7em", textTransform:"lowercase", color:"#888888"}}>scrapes most of the text, including text links</span>
                            </Box>)
                            }
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={state.scraperToUse === 'article'}
                                    onChange={handleChange}
                                    value="article"
                                    name="scraperToUse"
                                />
                            }
                            label={
                                (<Box display="flex" flexDirection="column">
                                <span>Use Article extraction scraper</span>
                                <span style={{fontSize:"0.7em", textTransform:"lowercase", color:"#888888"}}>scrapes only text in paragraphs</span>
                            </Box>)
                            }
                        />
                    </FormGroup>}
                    
                    <FormControl fullWidth={true} margin="normal">
                        <TextField
                            autoFocus
                            margin="normal"
                            name="name"
                            label="Session Name"
                            helperText="Give your session a name for easy reference"
                            type="text"
                            variant="outlined"
                            value={state.name}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                        <TextField
                            name="maxDepth"
                            label="Scrape Maximum Depth"
                            helperText="This is a Demo: Only max-depth of 0 is allowed."
                            variant="outlined"
                            type="number"
                            inputProps={{"min": 0, max: 7}}
                            value={state.maxDepth}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                        <TextField
                            name="listOfUrls"
                            label="List of URLs"
                            helperText="Urls to scrape, one url per line. no commas"
                            multiline
                            type="text"
                            rows={4}
                            value={state.listOfUrls}
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                        <TextField
                            name="listOfIgnoreUrls"
                            label="List of URLS to ignore"
                            helperText="these links will not be scraped"
                            multiline
                            rows={4}
                            value={state.listOfIgnoreUrls}
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                        <TextField
                            name="minTextLength"
                            label="Minimum words in text "
                            helperText="articles with word count below this length will be ignored"
                            type="number"
                            inputProps={{"min": 0 }}
                            value={state.minTextLength}
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                    <FormControlLabel
                            control={
                            <Checkbox
                                checked={state.enforceUniqueTextDownloads}
                                onChange={handleChange}
                                name="enforceUniqueTextDownloads"
                                color="primary"
                            />
                            }
                            label="Do not download results with duplicate text"
                        />
                    </FormControl>
                </DialogContent>
                {dialogType !== 'view' && 
                    <DialogActions style={{backgroundColor: "rgb(240, 240, 240)", paddingTop:"10px"}}>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveSession} variant="contained" color="secondary" disabled={isSaving}>
                            { isSaving && (<CircularProgress  style={{width: "20px", height: "20px", "marginLeft": "8px"}} />)}
                            Save
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </div>
    );
}

export default AddEditSessionDialog;