import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SessionsList from '../../app/components/SessionsList/SessionsList';
import Copyright from '../../components/Copyright/Copyright';
import AddEditSessionDialog from '../../components/AddEditSessionDialog/AddEditSessionDialog';
import AdminHelperBox from '../../admin/components/AdminHelperBox/AdminHelperBox';

import MainLogo from '../../components/MainLogo';
import UsageLeftWarning from '../../components/UsageLeftWarning/UsageLeftWarning';
import { useUserData} from '../../hooks';
import AppTopMenu from './AppTopMenu';
import {Helmet} from "react-helmet";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addSessionButton: {
    textAlign: 'center',
    padding: '10px'
  }
}));

function AppLayout({children, pageTitle="Dashboard"}) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [sessDialogIsOpen, setSessDialogIsOpen] = React.useState(false);
  const [userData, userDataLoading] = useUserData();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onAddSessionCLick = () => {
    setSessDialogIsOpen(true);
  }

  const onSessDialogClose = () => {
    setSessDialogIsOpen(false);
  }

  const container = undefined;

  const drawer = (
    <div>
      <AddEditSessionDialog open={sessDialogIsOpen} dialogType="add" onClose={onSessDialogClose} />
      <div className={classes.toolbar} style={{padding: "10px"}}>
        <MainLogo />
      </div>
      <Divider />
      <div className={classes.addSessionButton}>
           <Button variant="contained" color="secondary" onClick={onAddSessionCLick}>Add Session</Button>
      </div>
      
      <Divider />

      { (!userDataLoading && userData.sessionsCount) && <SessionsList /> }
      
    </div>
  );

  const TopBar = (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap className={classes.title}>
          {pageTitle}
        </Typography>
        <AppTopMenu />
        
      </Toolbar>
    </AppBar>
  );

  const Sidebar = (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );

  const renderMainDetails = function() {
    return (<>{children}</>);
  }

  

  return (
    <div className={classes.root}>
      <Helmet>
        <title>FireScraper - {pageTitle}</title>
      </Helmet>
      <CssBaseline />
      {TopBar}
      {Sidebar}
      
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <UsageLeftWarning/>
        <Container maxWidth="lg" className={classes.container}>
          
            <main>
              {renderMainDetails()}
            </main>
          
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
      <AdminHelperBox />
    </div>
  );
}

AppLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default AppLayout;
