
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { auth, googleProvider, facebookProvider, githubProvider } from '../../../config/firebase';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import GoogleLogo from '../../../media/icons/google_icon.svg';
import FacebookLogo from '../../../media/icons/facebook_icon.svg';
import GitHubLogo from '../../../media/icons/github_icon.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        border: '1px solid #ccc',
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    signUpButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #e07b39 90%)',
        border: 0,
        fontSize: 15,
        borderRadius: 6,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px'
    },

    loginButtonBox: {
        border: '1px solid #ccc',
        backgroundColor: '#ffcc00',
        fontSize: '60px'

    }
}));

export default function LoginPage() {
    const classes = useStyles();
    const history = useHistory();

    const signInWithGoogle = () => {
        auth.signInWithPopup(googleProvider).then((res) => {
          history.push("/app/dashboard");
        }).catch((error) => {
          //console.log(error.message)
        })
    }

    const signInWithFacebook = () => {
        auth.signInWithPopup(facebookProvider).then((res) => {
          history.push("/app/dashboard");
        }).catch((error) => {
          //console.log(error.message)
        })
    }

    const signInWithGithub = () => {
        auth.signInWithPopup(githubProvider).then((res) => {
          history.push("/app/dashboard");
        }).catch((error) => {
          //console.log(error.message)
        })
    }

    return (
        <Box className={classes.paper}>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box width={1} mt={3} p={2}>
                <Box>
                    <Button variant="outlined" size="large" fullWidth onClick={signInWithGoogle}>
                        <Avatar alt="Login with Google" src={GoogleLogo} className={classes.small}/>
                        &nbsp;&nbsp;Login With Google
                    </Button>
                </Box>
                <Box my={2}>
                    <Button variant="outlined" size="large" fullWidth onClick={signInWithFacebook}  disabled={true} >
                        <Avatar alt="Login with Facebook" src={FacebookLogo} className={classes.small} />
                        &nbsp;&nbsp;Login with Facebook
                    </Button>
                </Box>
                <Box mt={1}>
                    <Button variant="outlined" size="large" fullWidth onClick={signInWithGithub}  disabled={true}>
                        <Avatar alt="Login with GitHub" src={GitHubLogo} className={classes.small} />
                        &nbsp;&nbsp;Login with GitHub
                    </Button>
                </Box>
            </Box>
            <Box width={1} mt={3} borderTop={1} borderColor="grey.300" p={2} bgcolor="grey.100" textAlign="right">
                <Button size="small"  startIcon={<ChevronLeftIcon />} href="/">Back to Homepage</Button>
            </Box>
        </Box>
    )
}