export default function PrivacyPolicyPage() {
    return (
        <div className="p-4">
            <div className="row">
                <div className="col-12">
                    <h1 className="section-title">Privacy Policy</h1>
                    <p className="mt-5">We are committed to protecting your personal information and your right to privacy. If you
                    have any questions or concerns about our policy, or our practices with regards to your personal
                information, please contact us.</p>
                    <p>This Privacy Policy governs the privacy policies and practices of our Website, located at
                    FireScraper.com. Please read our Privacy Policy carefully as it will help you make informed decisions
                about sharing your personal information with us.</p>
                    <h2 className="mt-5">Information We Collect</h2>
                    <p>As a Visitor, you can browse our Website to find out more about our Website. You are not required to
                provide us with any personal information as a Visitor.</p>
                    <h3 className="mt-5">Information You Provide to Us</h3>
                    <p>We collect your personal information when you register with us ("User"), when you express an interest in
                    obtaining information about us or our products and services, when you participate in activities on our
                Website Site (such as usingour scraper) or otherwise contacting us.</p>
                    <p>Generally, you control the amount and type of information you provide to us when using our Website. The
                    personal information that we collect depends on the context of your interaction with us and the Website,
                    the choices you make and the products and features you use. The personal information we collect can
                include the following:</p>
                    <ul>
                        <li>Name, Email Address and Contact Data</li>
                        <li>Payment Information. If you choose to buy one of our Policy, we collect payment information through
                        our payment processor. All payment information is securely stored by our payment processor and we do
                    not have access to your sensitive information, such as credit card number or security code.</li>
                        <li>Business Information. If you use our scrape services we will collect a list of URLs from you that
                    may pertain to your business or other businesses.</li>
                    </ul>
                    <h4 className="mt-4">Cookies</h4>
                    <p>Our Website uses "Cookies" to identify the areas of our Website that you have visited. A Cookie is a
                    small piece of data stored on your computer or mobile device by your web browser. We use Cookies to
                    personalize the Content that you see on our Website. Most web browsers can be set to disable the use of
                    Cookies. However, if you disable Cookies, you may not be able to access functionality on our Website
                correctly or at all. We never place Personally Identifiable Information in Cookies.</p>
                    <h4 className="mt-4">Log Information</h4>
                    <p>We automatically receive information from your web browser or mobile device. This information includes
                    the name of the website from which you entered our Website, if any, as well as the name of the website
                    to which you're headed when you leave our website. This information also includes the IP address of your
                    computer/proxy server that you use to access the Internet, your Internet Website provider name, web
                    browser type, type of mobile device, and computer operating system. We use all of this information to
                analyze trends among our Users to help improve our Website.</p>
                    <h2 className="mt-5">Information Regarding Your Data Protection Rights Under General Data Protection Regulation
                (GDPR)</h2>
                    <p>For the purpose of this Privacy Policy, we are a Data Controller of your personal information.</p>
                    <p>If you are from the European Economic Area (EEA), our legal basis for collecting and using your personal
                    information, as described in this Privacy Policy, depends on the information we collect and the specific
                context in which we collect it. We may process your personal information because:</p>
                    <ul>
                        <li>We need to perform a contract with you, such as when you use our services</li>
                        <li>You have given us permission to do so</li>
                        <li>The processing is in our legitimate interests and it's not overridden by your rights</li>
                        <li>For payment processing purposes</li>
                        <li>To comply with the law</li>
                    </ul>
                    <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. In
                certain circumstances, you have the following data protection rights:</p>
                    <ul>
                        <li>The right to access, update or to delete the personal information we have on you</li>
                        <li>The right of rectification</li>
                        <li>The right to object</li>
                        <li>The right of restriction</li>
                        <li>The right to data portability</li>
                        <li>The right to withdraw consent</li>
                    </ul>
                    <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
                    <p>You have the right to complain to a Data Protection Authority about our collection and use of your
                    personal information. For more information, please contact your local data protection authority in the
                European Economic Area (EEA).</p>
                    <h2 className="mt-5">"Do Not Sell My Personal Information" Notice for California consumers under California
                Consumer Privacy Act (CCPA)</h2>
                    <p>Under the CCPA, California consumers have the right to:</p>
                    <ul>
                        <li>Request that a business that collects a consumer's personal data disclose the categories and
                    specific pieces of personal data that a business has collected about consumers.</li>
                        <li>Request that a business delete any personal data about the consumer that a business has collected.
                </li>
                        <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal
                    data.</li>
                    </ul>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these
                rights, please contact us.</p>
                    <h2 className="mt-5">Service Providers</h2>
                    <p>We employ third party companies and individuals to facilitate our Website ("Service Providers"), to
                    provide our Website on our behalf, to perform Website-related services or to assist us in analyzing how
                    our Website is used. These third-parties have access to your personal information only to perform these
                tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                    <h3 className="mt-4">Analytics</h3>
                    <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
                    Google uses the data collected to track and monitor the use of our Service. This data is shared with
                    other Google services. Google may use the collected data to contextualize and personalize the ads of its
                own advertising network.</p>
                    <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing
                    the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
                analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
                    <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web
                page: <a
                            href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>
                    </p>
                    <h3 className="mt-4">Payments processors</h3>
                    <p>We provide paid products and/or services on our Website. In that case, we use third-party services for
                payment processing (e.g. payment processors).</p>
                    <p>We will not store or collect your payment card details. That information is provided directly to our
                    third-party payment processors whose use of your personal information is governed by their Privacy
                    Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security
                Standards Council.</p>
                    <h2 className="mt-5">Contacting Us</h2>
                    <p>If there are any questions regarding this privacy policy you may contact us.</p>
                </div>
            </div>
        </div>
    )
}