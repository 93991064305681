
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import grey from '@material-ui/core/colors/grey';
import { SESSION_STATUS } from '../../enums';

interface Props {
    size?: number,
    status: SESSION_STATUS
}

export default function SessionStatusIcon({ size, status }: Props) {
    switch(status) {
        case SESSION_STATUS.IN_PROGRESS:
            return (<CircularProgress size={size ? size : "20px"} />);
        case SESSION_STATUS.DONE:
            return (<CheckCircleIcon style={{ color: green[600] }} />);
        // case SESSION_STATUS.WARNING:
            //    return (<ErrorIcon style={{ color: yellow[700] }}/>);
        case SESSION_STATUS.ABORTED:
            return (<CancelIcon style={{ color: red[300] }} />);
        case SESSION_STATUS.ERROR:
            return (<ErrorIcon style={{ color: red[500] }} />);
            case SESSION_STATUS.PAUSED:
                return (<PauseCircleFilledIcon style={{ color: grey[700] }} />);
        default:
            return (<HelpIcon style={{ color: grey[500] }} />);
    }
}
