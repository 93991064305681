
import { UserContext } from '../../../providers/UserProvider';
import {  useEffect, useState, useContext }  from 'react';
import { useParams } from 'react-router-dom';
import { Button, Link } from '@material-ui/core';
import { firefunctions } from "../../../config/firebase";

export default  function SessionDetailsPage(){
    const currentUser = useContext(UserContext);
    const params = useParams();

    const allowedUIDs = ['JMNJ3GSqgrSwgHY8tTzb6n9LcUr2', 'J7wYYTW6rbX3QQ819AgAR32PlUa2', '65ALEol6rJadUdSBb1owPoshRgq2'];

    
    const [claims, setClaims] = useState(null);
    useEffect(async () => {
            const idTokenResult = await currentUser.getIdTokenResult();
            const claims = idTokenResult.claims;
            setClaims(claims);
            //console.log('currentUser', claims, currentUser);
    }, [currentUser])


    const [isPinging, setIsPinging] = useState(false);
  const onPingAdminClick = () => {
    setIsPinging(true);
    var functionRef = firefunctions.httpsCallable('onAdminPing');
    functionRef({  }).then((msg) => {
    }).finally(()=> { 
      setIsPinging(false); 
    });
  }

    const adminBoxCSS = {
        "position": "fixed",
        "bottom": "5px",
        "right": "5px",
        "width": "300px",
        "backgroundColor": "#fff",
        "border": "3px solid #ccc",
        "padding": "3px",
        "paddingLeft": "10px",
        "height": "200px",
    };

    if(!allowedUIDs.includes(currentUser.uid)) {
        return null;
    }

    return (
        <div style={adminBoxCSS}>
        <h4 style={{marginBottom: "2px"}}>My Admin Links</h4>
        <Link target="_blank" href={`http://localhost:4000/firestore/users/${currentUser.uid}/sessions/${params.sessionId}`}>View log details (locally)</Link> <br/>
        <Link target="_blank" href={`https://console.firebase.google.com/u/0/project/react-firescraper-c4744/firestore/data/~2Fusers~2F${currentUser.uid}~2Fsessions~2F${params.sessionId}`}>View log details (remote-demo)</Link> <br/>
        <Link target="_blank" href={`https://console.firebase.google.com/u/0/project/firescraper-5efa3/firestore/data/~2Fusers~2F${currentUser.uid}~2Fsessions~2F${params.sessionId}`}>View log details (remote-prod)</Link>

        <Button onClick={onPingAdminClick}>Ping Admin</Button>
    </div>
    )
}