import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import landingImage from '../../../media/img/firescraper-landing-image.png';
import fImage1 from '../../../media/img/unlimited-urls.png';
import fImage2 from '../../../media/img/fast-scraping.png';
import fImage3 from '../../../media/img/live-updates.png';
import fImage4 from '../../../media/img/failsafe.png';
import fImage5 from '../../../media/img/filtering.png';
import fImage6 from '../../../media/img/learning.png';
import fImage7 from '../../../media/img/articles.png';
import fImage8 from '../../../media/img/export.png';
import fImage9 from '../../../media/img/cloud.png';

const useStyles = makeStyles({
    signUpButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #e07b39 90%)',
        border: 0,
        fontSize: 15,
        borderRadius: 6,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px'
    }
})

function TopBanner() {
    const classes = useStyles();
    return (
        <Box width={1} bgcolor="grey.900" p={6}>
            <Grid container spacing={1}>
                <Grid container item xs={12} sm={6}>
                    <Box width={1} mt={5} fontWeight={500} fontSize="60px" display="flex" flexDirection="row">
                        <Box color="grey.50">Fire</Box>
                        <Box color="#e07b39" fontWeight={700}>Scraper</Box>
                    </Box>
                    <Box mt={2} width={1} color="grey.50" mb={0} fontSize="25px">#1 tool for extracting articles from web pages</Box>
                    
                    <Box mt={0} width={1} color="grey.50" fontSize="15px">We scrape text from URLs. This tool produces a zipped folder of .txt files and a CSV file. Extracted text files can be used in topic modelling</Box>
                    <Box mb={5} width={1} mt="10px">
                        <Button variant="outlined" size="large" className={classes.signUpButton} href="/auth/login">
                            Try it for free
                        </Button>
                        <Button variant="outlined" size="large" className={classes.signUpButton} href="/auth/login" style={{marginLeft: '10px'}}>
                            Login
                        </Button>
                    </Box>
                </Grid>
                <Grid container item xs={false} sm={6}>
                    <img src={landingImage} alt="FireScraper" style={{maxWidth: "100%"}} />
                </Grid>
            </Grid>
        </Box>
    )
}

function FeaturesGrid() {
    const features = [
        {
            title:"Extract text from multiples web pages",
            img: fImage7,
            description: "FireScraper is the fastest tool on the web for extracting text from multiple web pages"
        },
        {
            title:"Unlimited URLs. Unlimited projects",
            img: fImage1,
            description: "With firescraper you can extract text from unlimited urls to the depth of your choice!"
        },
        {
            title:"Export files to CSV or .txt files",
            img: fImage8,
            description: "After extracting your articles you can download all articles as a CSV file or a zip folder with .txt files"
        },
        {
            title:"Super fast scraping!",
            img: fImage2,
            description: "Extract thousands of web pages in a matter of minutes not hours! No more waiting on long scraping processes to finish."
        },
        {
            title:"Live Updates",
            img: fImage3,
            description: "You dont have to be in the dark while its scraping, updates are pushed to your browser instantly."
        },
        {
            title:"Fail Safe + Retries",
            img: fImage4,
            description: "FireScraper handle scrape fails and performs retries thus reducing number of failed scrapes."
        },
        {
            title:"Robust Filtering",
            img: fImage5,
            description: "You can define scrape depth, URLs to ignore, limit results by length of text and much more."
        },
        {
            title:"All your data in one place",
            img: fImage9,
            description: "All your scrape projects in one place. No more folders spread out everywhere in your computer"
        },
        {
            title:"Academia ML Research",
            img: fImage6,
            description: "FireScraper is used for machine learning research, specifically for topic modelling."
        }
    ];

    return (
        <Box width={1} p={2} mt={3}>
            <Grid container justify="center" spacing={3}>
                {
                    features.map((f, key) => (
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" flexDirection="column" textAlign="center" justifyContent="center">
                                <Box width={1}>
                                    <img src={f.img} alt={f.title} style={{maxHeight: "80px", maxWidth: "80px"}} />
                                </Box>
                                <Box width={1}>
                                <Typography gutterBottom variant="h5" component="h2">
                                        {f.title}
                                    </Typography>
                                    <Typography component="p">
                                        {f.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>  
        </Box>
    )
}

export default () => {
    return(
        <React.Fragment>
            <TopBanner />
            <FeaturesGrid />
        </React.Fragment>
    )
}