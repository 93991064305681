import { useEffect, useState} from 'react';
import {  auth } from '../config/firebase';
import firebase from 'firebase';


export default function useAuth() {
    const [user, setUser] = useState<firebase.User | undefined>(undefined);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if(user == null) {
                setUser(undefined);
            } else {
                setUser(user);
            }
            setIsLoaded(true);
        })
    },[])

    return [user, isLoaded];
}