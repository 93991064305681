import { Box, Button, Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import HelpIcon from "@material-ui/icons/Help";
import CancelIcon from "@material-ui/icons/Cancel";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import grey from "@material-ui/core/colors/grey";

interface ButtonDetails {
  onClick?:any,
  title: string,
  color?: string
}

interface Props {
  type: string,
  title?: string,
  details?: string,
  buttons?: any[],
  children?: JSX.Element | JSX.Element[] | string | string[];
}
export default function InPageNotificationBox({ type, title, details, buttons, children}: Props) {
  const getIcon = () => {
    switch (type) {
      case "success":
        return <CheckCircleIcon style={{ color: green[600], fontSize: 80 }} />;
      case "warning":
        return <WarningIcon style={{ color: yellow[700], fontSize: 80 }} />;
      case "error":
        return <CancelIcon style={{ color: red[500], fontSize: 80 }} />;
      case "info":
        return <HelpIcon style={{ color: grey[500], fontSize: 80 }} />;
    }
  };

  return (

        <Box display="flex" flexDirection="column" width={1}  p={3}>
          <Box width={1} textAlign="center"> {getIcon()}</Box>
          { title && <Box width={1} p={1}>
            <Typography align="center" variant="h4">
              {title}
            </Typography>
          </Box>}
          { details && <Box width={1} my={2}>
            <Typography align="center">{details}</Typography>
          </Box>}
          {children && (<Box width={1}>{children}</Box>)}
          { buttons && (
            <Box display="flex" borderTop={1} width={1} mt={3} pt={3} alignContent="center" flexDirection="row" justifyContent="center" borderColor={grey[200]}>
              { 
                buttons?.map((but) => 
                  <Button {...but}>{but.title}</Button>
                )
              }
            </Box>
          )}
        </Box>

  );
}
