import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import SessionStatusIcon from '../../../components/SessionStatusIcon/SessionStatusIcon';
import { useParams } from 'react-router-dom';
import { useSessionsList } from '../../../hooks';
import { LoadSpinner } from '../../../components/AppUtil';
import moment from 'moment';
import { Box, IconButton, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';


export default function SessionsList() {
    const params = useParams();
    const [sessionsList, sessionsListLoading] = useSessionsList();
    const [filterText, setFilterText] = useState('');


    const onFilterChange = function(e) {
        setFilterText(e.target.value.trim());
    }

    const clearFilter = () => {
        setFilterText('');
    }

    const getItemCreateDate = (dt) => {
        
        if(dt.seconds) {
            return "created " + moment(dt.toDate()).calendar()
        } else if(dt) {
            return "created " + moment(dt).calendar();
        } else {
            return "";
        }
        
    }

    return (
        <>
        { sessionsListLoading && <LoadSpinner loadingMessage="" /> }
        { (!sessionsListLoading && sessionsList) && 
            <List>
                <ListItem>
                    <TextField 
                        id="outlined-basic" 
                        size="small"
                        onChange = {onFilterChange}
                        value={filterText}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                            endAdornment:(filterText && 
                            <InputAdornment position="end">
                                <IconButton onClick={clearFilter} size="small">
                                    <CancelIcon />
                                </IconButton>
                                
                            </InputAdornment>)
                          }}
                        label="Filter list" variant="outlined" fullWidth />
                </ListItem>
                <>
                    {
                        sessionsList.filter((x) => x.name && x.name.includes(filterText)).map((session, index) => (
                            <ListItem button key={session.id} component={Link} to={`/app/sessions/${session.id}`} selected={ params.sessionId === session.id }>
                                <ListItemIcon>
                                    <SessionStatusIcon status={session.status} />
                                </ListItemIcon>
                                <ListItemText primary={session.name ? session.name : "[No name]"} secondary={(<Box fontSize={11}>{getItemCreateDate(session.dateCreated)}</Box>)} />
                            </ListItem>
                        ))
                    }
                </>
            </List>
        }
        </>
    );
}