
import { useParams } from 'react-router-dom';
import InPageNotification from '../../components/InPageNotificationBox/InPageNotificationBox';

interface ParamTypes {
    notificationType: string
}

export default function NotificationsPage() {

    const { notificationType } = useParams<ParamTypes>()
    const goToAppBut = [
        {title: "To go app", href: "/app/dashboard" }
    ]

    switch(notificationType) {
        case 'payment-failed':
            return (
                <InPageNotification type="error" title="Payment failed" details="You payment did not go through " buttons={goToAppBut} />
            )
        case 'payment-successful':
            return (<InPageNotification type="success" title="Payment Successful!" details="Your payment has been processed successfully. " buttons={goToAppBut} />)
        case '404':
            return (<InPageNotification type="error" title="Error 404" details="It looks like you've reached a URL that doesnt exist." buttons={goToAppBut} />)
        case '500':
        default:
            return (<InPageNotification type="error" title="Error 404" details="It looks like you've reached a URL that doesnt exist." buttons={goToAppBut} />)
    }
}