import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Copyright from '../components/Copyright/Copyright';
import MainLogo from '../components/MainLogo';
import { auth } from '../config/firebase';
import ContactUsDialog from '../components/ContactUsDialog/ContactUsDialog';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  footerLink : {
    color: "rgb(224, 123, 57)",
    fontWeight: "500"
  }
}));




export default function PublicLayout({children, pageTitle="Home"}) {
  const [contactDialogIsOpen, setContactDialogIsOpen] = React.useState(false);

  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
      auth.onAuthStateChanged(async (user) => {
        setIsLoggedIn(user != null);
      })
  },[]);


  // We can use inline-style
  const googleButStyle = {
    background: 'linear-gradient(45deg, #DB4437 30%, #DB4437 90%)',
    borderRadius: 10,
    border: 0,
    color: 'white',
    height: 40,
    padding: '0 20px'
  };

  const onContactUsClick = () => {
    setContactDialogIsOpen(true);
  }

  const onContactDialogClose = () => {
    setContactDialogIsOpen(false);
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <ContactUsDialog open={contactDialogIsOpen} dialogType="add" onClose={onContactDialogClose} />
      <Container maxWidth="lg" component="main">
        <Box bgcolor="white" display="flex" flexDirection="column">
          <Box>
          <AppBar position="static" color="default" elevation={0} className={classes.appBar}  >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            <MainLogo />
          </Typography>
          <nav>
            <Link variant="button" color="textPrimary" href="/" className={classes.link}>
              Home
            </Link>
            <Link variant="button" color="textPrimary" href="/pricing" className={classes.link}>
              Pricing
            </Link>
            <Link variant="button" color="textPrimary" onClick={onContactUsClick} className={classes.link}>
              Contact us
            </Link>
          </nav>
          {
            isLoggedIn ? 
            <Button href="/app/dashboard" color="primary" variant="outlined" className={classes.link}>Go to App</Button>
            :
            <Button color="palette.secondary" style={googleButStyle} href="/auth/login">Login</Button>
          }
        </Toolbar>
      </AppBar>
          </Box>
          <Box>{children}</Box>
          <Box bgcolor="grey.300" mt={5} p={2}>
          <Box mt={1} display="flex"  p={1} flexDirection="row" flexWrap="nowrap" color="grey.500">
            <Box borderRight={1} borderColor="grey.400" pr={1} mr={1}><Link className={classes.footerLink} href="/">Home</Link></Box>
            <Box borderRight={1} borderColor="grey.400" pr={1} mr={1}><Link className={classes.footerLink} href="/termsofuse">Terms of use</Link></Box>
            <Box borderRight={1} borderColor="grey.400" pr={1} mr={1}><Link className={classes.footerLink} href="/privacypolicy">Privacy Policy</Link></Box>
            <Box borderRight={1} borderColor="grey.400" pr={1} mr={1}><Link className={classes.footerLink} onClick={onContactUsClick}>Contact Us</Link></Box>
          
            <Box flexGrow={1} textAlign="right" color="grey.500">
              <Copyright />
            </Box>
          </Box>
          </Box>
        </Box>
      </Container>
      
    </React.Fragment>
  );
}