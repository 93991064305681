import React, { useEffect, useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import { firefunctions } from '../../config/firebase';
import {useStripe} from '@stripe/react-stripe-js';
import Radio from '@material-ui/core/Radio';
import { useFireCallable } from '../../hooks';
import { LoadSpinner } from '../AppUtil';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2';

const useStyles = makeStyles({
  priceTitle: {
    fontSize: '18px',
    display: 'block'
  },
  priceDescription: {
    fontSize: '14px',
    display: 'block',
    color: '#ccc',
  },
  priceAmount: {
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'block'
  },
  priceCurrency: {
    color: '#ccc',
    fontSize: '12px',
    display: 'block',

  }
});

function BuyCreditDialog({ open = false, onClose = () => {} }) {
  const classes = useStyles();
  const [productsList, productsListLoading] = useFireCallable('getProducts', { returnUrl: window.location.origin });
  const stripe = useStripe();
  const handleClose = () => {
    onClose();
  };

  const [selectedPrice, setSelectedPrice] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const handlePriceChange = (event) => {
    setSelectedPrice(event.target.value);
  }

  const oneTimePayment = async function() {
    setIsSaving(true);
    var functionRef = firefunctions.httpsCallable('oneTimePayment');
    try {
      const session = await functionRef({ returnUrl: window.location.origin, price: selectedPrice });
      setIsSaving(false);
      const result = await stripe.redirectToCheckout({
          sessionId: session.data.id,
      });
    } catch(err) {
      Swal.fire(
        'Payment processing failed!',
        'An error occured while attempting to process payment. Admin has been notified.',
        'error'
      )
      setIsSaving(false);
    }
  }

  return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: "rgb(240, 240, 240)" }}
          >
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1}>Buy More Credits </Box>
              <Box>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <CloseIcon size="sm" />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            {productsListLoading && <LoadSpinner/> }
            {!productsListLoading && 
                <Box width={1} display="flex" flexDirection="column">
                    {productsList.map((p, k) => (
                        <Box width={1} display="flex" flexDirection="row"  my={1} key={k}>
                            <Box>
                              <Radio
                                checked={selectedPrice === p.priceid}
                                onChange={handlePriceChange}
                                value={p.priceid}
                                color="default"
                                name="radio-button-price-item"
                              />
                            </Box>
                            <Box width={0.6}>
                                <span className={classes.priceTitle}>
                                  {p.title}
                                </span>
                                <span className={classes.priceDescription}>{p.description}</span>
                            </Box>
                            <Box width={0.3} textAlign="center">
                              <span className={classes.priceAmount}>
                              ${(p.price / 100).toFixed(2)}
                              </span>
                              <span className={classes.priceCurrency}>
                                {p.currency}
                              </span>
                            </Box>
                        </Box>
                    ))}
                </Box>
            }
    
          </DialogContent>
          <DialogActions
            mt={2}
            pt={2}
            style={{
              backgroundColor: "rgb(240, 240, 240)",
              paddingTop: "10px",
            }}
          >
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={oneTimePayment} disabled={!selectedPrice || isSaving}>
            { isSaving && (<CircularProgress  style={{width: "20px", height: "20px", "margin-left": "8px"}} />)}
              Pay Now
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

BuyCreditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default BuyCreditDialog;
