import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
      },
      cardHeader: {
        backgroundColor:
          theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
      },
      cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
      },
  }));
  

const featuresList = [
  'Export files to CSV & .txt files',
  'Live updates during scraping',
  'Data kept for up to 4 months',
  'Robust filtering',
  'Help center access',
  'Priority email support',
  'Unlimited projects',
];

const tiers = [
    {
      title: 'Starter',
      price: '20',
      description: "for 20,000 pages",
    },
    {
      title: 'Silver',
      subheader: 'Most popular',
      price: '50',
      description: "for 60,000 pages"
      
    },
    {
      title: 'Gold',
      price: '100',
      description: "for 150,000 pages",
    },
  ];

export default function PricingPage() {
    const classes = useStyles();
    return(
        <React.Fragment>
            {/* Hero unit */}
      <Container maxWidth="md" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Pricing
        </Typography>

        <Typography variant="h4" align="center" color="textSecondary" component="p" style={{padding:"10px", marginBottom: "20px" }}>
            
            <span style={{backgroundColor:"#FF8D24", color: "white" }}>Our pricing is simple. <br/>No subscriptions. You pay for what you use.</span>
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          Guess what? <span style={{backgroundColor: "#404040", color: "#F8F8F8"}}>You first 1000 credits are on us.</span> <br/>This gives you time to try our application before you even spend a cent!
        </Typography>
      </Container>
      {/* End hero unit */}
        <Grid container spacing={5} alignItems="flex-end" style={{padding:"40px"}}>
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Silver' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    
                  </div>
                  <div className={classes.cardPricing}>
                  
                    <Chip color="secondary" style={{fontSize: "16px"}}
                   label={tier.description} />
                  </div>
                  
                  <ul>
                    {featuresList.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h6" align="center" color="textSecondary" component="p">
          Got special needs that cannot be met by our plans? <br/>Contact us, we will be happy to help you.
        </Typography>
        </React.Fragment>
    )
}