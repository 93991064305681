import React, {useContext }  from 'react';
import { Route, Redirect  } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';

const PublicRoute = ({component: Component, redirectLoggedIn = false, layout: Layout, ...rest}) => {
    const user = useContext(UserContext);

    if(redirectLoggedIn && user) {
        return <Redirect to="/app/dashboard" />
    } else {
        return (
            // restricted = false meaning public route
            // restricted = true meaning restricted route
            <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />
        );
    }

};

export default PublicRoute;