import React, {useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import AdminLayout from '../layouts/AdminLayout/index';
import {  useEffect, useState,  }  from 'react';

const AdminRoute = ({component: Component, pageTitle="", ...rest}) => {
    const user = useContext(UserContext);

    const [claims, setClaims] = useState(null);
    const [claimsLoading, setClaimsLoading] = useState(true);
    useEffect(() => {
            if(user) {
                setClaimsLoading(true);
                user.getIdTokenResult()
                .then((idTokenResult)=> {
                    setClaims(idTokenResult.claims);
                })
                .catch((err)=> {})
                .finally(()=> {
                    setClaimsLoading(false);
                });
            } 
    }, [user]);


    if(claimsLoading && !claims) {
        return (
            <span>Loading admin.. wait...</span>
        )
    }
    else if(user && claims && claims.admin) {
        return(
            <Route {...rest} render={props => (
            <AdminLayout pageTitle={pageTitle}>
                    <Component {...props} />
                </AdminLayout>
            )} />
        )
    } else {
        return (<p>Invalid page</p>)
    }

};

export default AdminRoute;