import React, {useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';

const PrivateRoute = ({component: Component, layout: Layout, pageTitle="", ...rest}) => {
    const user = useContext(UserContext);
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            user != null ?
                <Layout pageTitle={pageTitle}>
                    <Component {...props} />
                </Layout>
            : <Redirect to="/auth/login" />
        )} />
    );
};

export default PrivateRoute;