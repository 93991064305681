import {  useEffect, useState }  from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import SessionActions from './components/SessionActions';
import SessionAlert from "./components/SessionAlert";
import LogsFilter from "./components/LogsFilter";
import LogsList from "./components/LogsList";
import LogsPager from "./components/LogsPager";
import { LoadSpinner } from '../../../components/AppUtil';
import { useSessionData, useSessionCounts, usePagedSessionLogs, useSessionStats } from '../../../hooks';
import { Chip, Badge } from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Computer';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import { SESSION_STATUS } from '../../../enums';
import { Redirect } from "react-router-dom";

const FEATURE_MLSCRAPER = process.env.REACT_APP_FEATURE_MLSCRAPER === "true";

export default function SessionDetailsPage(){
    const [sessionFilter, setSessionFilter] = useState([]);
    const defaultPagerState = { currentPage: 1, pageSize:50, previousPage: null, lastAction: null };
    const [pagerState, setPagerState] = useState(defaultPagerState);

    const params = useParams();
    const [sessionData, sessionDataIsLoading] = useSessionData(params.sessionId, [params]);
    const [sessionCounts, sessionCountsIsLoading] = useSessionCounts(params.sessionId, [params]);
    const [sessionLogs, sessionLogsIsLoading, dataSourceInfo] = usePagedSessionLogs(params.sessionId, sessionFilter, pagerState);
    const [sessionStats, sessionStatsIsLoading] = useSessionStats(params.sessionId)

    const getScraperChip = function() {
        if(!sessionData || !FEATURE_MLSCRAPER) { return null; }
        if(sessionData.scraperToUse === "ml") {
            return (<Chip size="small" label="topic modelling" />);
        } else {
            return (<Chip size="small" label="article extraction" />);
        }
    }

    useEffect(() => {
        setPagerState(defaultPagerState);
        setSessionFilter([]);
    }, [params])


    
    return (
        <div>
            {(sessionDataIsLoading || sessionCountsIsLoading) && <LoadSpinner />}
            {(!sessionDataIsLoading && !sessionData) && <Redirect to="/app/dashboard" />}
            {(sessionData && sessionCounts) && 
                <>
                <Box display="flex" flexDirection='row' p={1} mb={1}>
                    <Box flexGrow={1}>
                        <Typography variant="h5"> { sessionData.name } { getScraperChip() } </Typography>                            
                    </Box>
                    { (!sessionStatsIsLoading && sessionData.status === SESSION_STATUS.IN_PROGRESS) &&
                        <Box display="flex" flexDirection='row' mr={5} alignItems="center">
                            <Box mr={2}>
                                <Badge color="secondary" badgeContent={sessionStats.queueLength} max={99999} min={0} showZero>
                                    <Chip label="Queued URLs" avatar={<LinearScaleIcon />} variant="outlined" size="small" />
                                </Badge>
                            </Box>
                            <Box>
                                <Badge color="secondary" badgeContent={sessionStats.serverInstancesCount} showZero>
                                    <Chip label="Servers in use " avatar={<ComputerIcon />} variant="outlined"  size="small"/>
                                </Badge>
                            </Box>
                        </Box>
                    }
                    <Box>
                        <SessionActions sessionData = {sessionData} />
                    </Box>
                </Box>
                <Paper>
                    <SessionAlert sessionData={sessionData} sessionCounts={ sessionCounts } />
                    <LogsFilter sessionData={sessionData}  sessionCounts={ sessionCounts }  onSetFilter={setSessionFilter} />
                    <LogsPager sessionData={sessionData}  sessionCounts={ sessionCounts } onPageStateChange={setPagerState} dataSourceInfo={dataSourceInfo} />
                    { sessionLogsIsLoading && <LoadSpinner loadingMessage="Loading logs" />}
                    { sessionLogs && <LogsList sessionLogs={sessionLogs} /> }
                </Paper>
                </>
            }

        </div>
    )
    
    
}